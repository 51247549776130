<template>
  <div>
    <el-dialog :title="text + '题目类型'"
               v-dialogDrag
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="120px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="类型名称"
                          prop="question_type_name">
              <el-input placeholder=" 输入如：选择题..."
                        v-model="form.question_type_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="自动批改">
              <el-radio-group v-model="form.auto_judge">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="所属科目"
                          prop="subject_id">
              <el-select style="width:100%"
                         v-model="form.subject_id"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in $parent.subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="所属显示类型"
                          prop="topic">
              <el-select style="width:100%"
                         v-model="form.topic"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in topicList"
                           :key="item.topic"
                           :label="item.topic_name"
                           :value="item.topic">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷地区">
              <el-select style="width:100%"
                         v-model="form.paper_districts"
                         clearable
                         filterable
                         multiple
                         placeholder="请选择">
                <el-option v-for="item in disList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getShowList } from '@/api/topicShowType.js'

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        question_type_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        topic: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      text: '',
      topicList: []
    }
  },
  mounted () {
    this.getDisList()
    this.getTopicShowList()
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    async getTopicShowList () {
      const { data } = await getShowList()
      this.topicList = data
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.paper_districts = form.paper_districts.join(',')
        this.$http({
          url: '/api/v2/paper/question_type_add',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },
    getDisList () {
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.disList = res.data.list
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>