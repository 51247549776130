<template>
  <div>
    <el-form label-width="80px"
             :model="form">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目:">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="add">新增</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'question_type_id', align: 'center', label: '题目类型编号', width: '', showToopic: false },
        { prop: 'question_type_name', align: 'center', label: '题目类型名称', width: '', showToopic: false },
        { prop: 'topic_name', align: 'center', label: '题目显示类型', width: '', showToopic: false },
        { prop: 'auto_judge', align: 'center', label: '是否自动批改', width: '', showToopic: false, isStatus: true },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: false },
        { prop: 'districts', align: 'center', label: '试卷地区', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      form: {
        subject_id: ''
      },
      tableData: [],
      subjectList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'slcz_paperType_admin',
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
    this.getSubjectList()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getClass (prop, value) {
      if (prop == 'auto_judge') {
        return value ? 'pass' : 'notpass'
      }

      if (prop == 'districts') {
        return ''
      }
    },
    getValue (prop, value) {
      if (prop == 'auto_judge') {
        return value ? '是' : '否'
      }
      if (prop == 'districts') {
        var str = ''
        value.forEach(item => {
          str += item.paper_district_name + ','
        });
        return str
      }
    },
    initData (page, limit) {
      let params = {
        page,
        limit,
        subject_id: this.form.subject_id
      }
      this.$http({
        url: '/api/v2/paper/question_type',
        method: 'get',
        params
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    editRow (row) {
      let form = {
        question_type_id: row.question_type_id,
        subject_id: row.subject_id,
        paper_districts: row.districts.map(item => {
          return item.paper_district_id
        }),
        question_type_name: row.question_type_name,
        auto_judge: row.auto_judge,
        topic: row.topic
      }
      console.log('row', form)

      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    add () {
      let form = {
        subject_id: '',
        paper_districts: [],
        question_type_name: '',
        auto_judge: 0
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((() => {
        this.$http({
          url: '/api/v2/paper/question_type_del',
          method: 'POST',
          data: {
            question_type_id: row.question_type_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      }))
    }
  }
}
</script>

<style lang="scss">
</style>